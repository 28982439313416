import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { MainBackgroundVector } from '../assets';
import { NewsletterForm } from '../components';
import CancelSubscriptionForm from '../components/cancelsubscription.form.component';
import useGoogleTag from '../utils/gtag';

const FAQPageContainer = styled.div`
    width: 100%;
`

export const BackgroundImage = styled.img`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`

const GeneralLayout = styled.div`
    display: flex;
    gap: 30px;
    padding: 0 var(--global-padding);
    flex-wrap: wrap;
    margin: 50px 0;
`

const ContactsLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    min-width: 300px;
    max-width: 450px;
    background: #000;
    background: linear-gradient(to bottom, rgba(0,0,0,1), #101010);
    box-sizing: border-box;
    padding: 50px;
`

const ContactSection = styled.div`
    flex: 1;
    min-width: 300px;
`

const CancelSubscription = () => {

    // GTAG
    const gtag = process.env.REACT_APP_GOOGLE_TAG || "";
    useGoogleTag(gtag)

    return (
        <>
        <FAQPageContainer>
            <BackgroundImage src={MainBackgroundVector} />

            <GeneralLayout style={{margin: '100px 0'}}>
                <ContactsLeftContainer>
                    <Typography sx={{
                        fontSize: '48px',
                        fontFamily: 'var(--head-text), Poppins, sans-serif',
                        color: 'var(--primary)',
                        fontWeight: 600,
                        lineHeight: '52px',
                        '@media screen and (max-width: 600px)': {
                            fontSize: '32px',
                            lineHeight: '48px'
                        }
                    }}>
                        CANCEL <br />SUBSCRIPTION
                    </Typography>
                </ContactsLeftContainer>
                <ContactSection>
                    <div className="faq-section-head-text">ARE YOU SURE YOU WANT TO CANCEL YOUR SUBSCRIPTION?</div>
                    {/* Contact Form: */}
                    <CancelSubscriptionForm />

                </ContactSection>
            </GeneralLayout>


            <div style={{ padding: 'var(--global-padding)' }}>
                <div className="newsletter-container-pricing-page flex aln-items-cnt">
                    <div className="newsletter-section-header flex flex-dir-col">
                        <span className="head-txt-off-white font-sz-small">SUBSCRIBE TO</span>
                        <span className="head-txt-off-white font-sz-small">OUR&nbsp;
                            <span className="head-txt-primary">ROKK</span>
                        </span>
                        <span className="head-txt-primary font-sz-small">NEWSLETTER</span>
                    </div>
                    <NewsletterForm />
                </div>
            </div>
            </FAQPageContainer>
        </>
    )
}

export default CancelSubscription