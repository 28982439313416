import { BandContainer, FormContainer } from '../../components/exports/band.registration.styles'
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { ArtistRegisterStep1, ArtistRegisterStep2, ArtistRegisterStep3, ArtistRegisterStep4, ArtistRegisterStep5, FormStepper } from '../../components';
import { useState } from 'react';
import useGoogleTag from '../../utils/gtag';

const RegisterBand = () => {

    // GTAG
    const gtag = process.env.REACT_APP_GOOGLE_TAG || "";
    useGoogleTag(gtag)


    // const [activeStep, setActiveStep] = useSessionStorage<number>('activeStep', 0);
    const [activeStep, setActiveStep] = useState<number>(0)


    return (
        <BandContainer>
            <FormStepper activeStep={activeStep} />
            {/* The actual form goes here */}
            <FormContainer>
                {activeStep === 0 && <ArtistRegisterStep1 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 1 && <ArtistRegisterStep2 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 2 && <ArtistRegisterStep3 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 3 && <ArtistRegisterStep4 activeStep={activeStep} setActiveStep={setActiveStep} />}
                {activeStep === 4 && <ArtistRegisterStep5 activeStep={activeStep} setActiveStep={setActiveStep} />}
            </FormContainer>
        </BandContainer>
    );
}

export default RegisterBand;
