import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useStateContext } from '../../context/StateContextAPI';
import { useNavigate } from 'react-router-dom';
import useGoogleTag from '../../utils/gtag';

const LoginContainer = styled.div`
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const FlexBox = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
`;

export const LoginPage = () => {

    // GTAG
    const gtag = process.env.REACT_APP_GOOGLE_TAG || "";
    useGoogleTag(gtag)

    const { setActivePage } = useStateContext();

    const navigate = useNavigate();

    useEffect(() => {
        setActivePage(() => "")
        window.scrollTo(0, 0);
    }, [setActivePage])

    return (
        <LoginContainer>
            <div className="head-text artist-page-head-text">
                <div>
                    <span className="head-txt-off-white">LOGIN&nbsp;</span>
                    <span className="head-txt-primary">OR&nbsp;</span>
                    <span className="head-txt-off-white">REGISTER</span>

                </div>

            </div>
            <Typography
                sx={{ color: 'var(--light-gray)' }}
            >
                Login to your rokk account
            </Typography>

            <FlexBox>
                <Button
                    sx={{
                        width: '200px',
                        height: '70px',
                        color: '#ff8303',
                        background: '#ff830305',
                        '&:hover': {
                            background: '#ff830310'
                        }
                    }}

                    onClick={() => window.location.href = "https://web.rokk.app/"}
                >
                    Login as User
                </Button>
                <Button
                    sx={{
                        width: '200px',
                        height: '70px',
                        color: '#ff8303',
                        background: '#ff830305',
                        '&:hover': {
                            background: '#ff830310'
                        }
                    }}
                    onClick={() => window.location.href="https://rokk.app/register-new-artist/"}
                    // onClick={() => navigate("../register-new-artist/")}
                >
                    Login as Artist
                </Button>
            </FlexBox >
        </LoginContainer >
    )
}