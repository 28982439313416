// src/utils/gtag.tsx
import { useEffect } from "react";

const useGoogleTag = (gtagId: string) => {
  useEffect(() => {
    if (!gtagId) return;

    // Check if the script already exists to prevent duplicate injections
    if (document.getElementById("gtag-script")) return;

    // Create external script tag
    const script = document.createElement("script");
    script.id = "gtag-script";
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    document.head.appendChild(script);

    // Create inline script for initialization
    const scriptInline = document.createElement("script");
    scriptInline.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtagId}');
    `;
    document.head.appendChild(scriptInline);
  }, [gtagId]);
};

export default useGoogleTag;
